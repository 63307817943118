<template>
  <div class="edit-ls__wrapper">
    <LxpBreadcrumb
      :items="getBreadCrumbs"
      @click="navigateToLink"
      v-if="isLearningSpaceEdit"
    />
    <h5 v-if="currentSpace && isLearningSpaceEdit">{{ currentSpace.name }}</h5>
    <div class="edit-ls__wrapper--div">
      <LxpLoader v-if="isLoading" />
      <LearningSpace
        v-else
        ref="learningSpace"
        :isEdit="true"
        :key="count"
        :spaceObj="currentSpace"
        :initiatives="initiatives"
        :sections="sections"
        :featuredResources="featuredResources"
        :currentInitiativeId="currentInitiativeIdData"
        :filterOptions="filterOptions"
        :urlList="getURLList"
        :token="authToken"
        :tags="tags"
        @info-updated="onUpdate"
        @initiative-updated="onUpdateInitiative"
        @featured-resource-updated="onFeaturedResourcesUpdate"
        @manage-section-updated="onManageSectionUpdate"
        @toggle-updated="onToggleUpdate"
        @click-empty-state-cta="onEmptyStateCTA"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LearningSpace, LxpLoader, LxpBreadcrumb } from "didactica";

import fetchSpaceList from "../Partner/mixins/fetchSpaceList";
export default {
  mixins: [fetchSpaceList],
  components: {
    LearningSpace,
    LxpLoader,
    LxpBreadcrumb
  },

  mounted() {
    if (!this.currentSpace) {
      this.$router.push({ name: "LearningSpacesHome" });
    }
  },
  computed: {
    ...mapGetters([
      "getSpacesList",
      "getInitiativesList",
      "allConfig",
      "getInitiativeDetail",
      "getFeaturedResourcesList",
      "getManageSectionList",
      "getTagsList"
    ]),

    currentOrg() {
      return this.$route.params.org;
    },
    currentId() {
      return this.$route.params.id;
    },
    currentInitiativeId() {
      return this.$route.params.id;
    },
    currentSpace() {
      return this.getSpacesList.find(
        space => space.org_short_name === this.currentOrg
      );
    },
    initiatives() {
      return this.getInitiativesList.filter(
        i => i.org_short_name === this.currentOrg
      );
    },
    initiativeDetail() {
      return this.getInitiativeDetail.find(
        space => space.org_short_name === this.currentOrg
      );
    },

    tags() {
      return this.getTagsList;
    },
    filterOptions() {
      return this.getTagsList;
    },

    authToken() {
      return `Bearer ${this.$keycloak.token}`;
    },
    getBreadCrumbs() {
      return [
        {
          text: "Learning Spaces",
          to: { name: "LearningSpacesHome" }
        },
        {
          text: this.currentOrg,
          to: { path: "/" }
        }
      ];
    },

    getURLList() {
      return {
        UPLOAD: {
          method: "post",
          url: `${this.allConfig.baseURL}/spaces/upload-image/`
        },
        LIST_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/discovery-paginated?org=${this.currentOrg}&language=en`
        },
        LIST_FEATURED_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/discovery-paginated?org=${this.currentOrg}&enforce_org_in_id=true`
        },
        FILTER_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/tags/filter-paginated/?tags=[{tagids}]&language={lang}`
        },
        UPDATE_SPACE: {
          method: "patch",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/`
        },
        UPDATE_ABOUT: {
          method: "patch",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/about/`
        },
        UPDATE_SOCIAL_NETWORK: {
          method: "patch",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/social-networks/`
        },
        LIST_INITIATIVE: {
          method: "get",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/initiatives/${this.currentId}/`
        },

        UPDATE_FEATURED_RESOURCES: {
          method: "put",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/courses/featured/`
        },
        UPDATE_INITIATIVE: {
          method: "patch",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/initiatives`
        },
        UPDATE_MANAGE_SECTION: {
          method: "patch",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/sections/`
        },
        GET_MANAGE_SECTION: {
          method: "get",
          url: `${this.allConfig.baseURL}/spaces/${this.currentOrg}/sections/`
        },
        GET_TAGS: {
          method: "get",
          url: `${this.allConfig.baseURL}/tags/live/?org=${this.org}`
        }
      };
    },
    isLearningSpaceEdit() {
      return this.$route.name !== "EditPartnerLearningSpace";
    }
  },
  data() {
    return {
      isLoading: true,
      count: 0,
      featuredResources: [],
      sections: [],
      currentInitiativeIdData: 0,
      renderCount: 0
    };
  },
  watch: {
    getSpacesList() {
      if (!this.currentSpace) {
        this.go404();
      }
    }
  },
  created() {
    if (this.getSpacesList.length === 0) {
      this.init();
    }
    this.getManageSection();
  },
  methods: {
    go404() {
      this.$router.push({ name: "NotFound" });
    },
    goHome() {
      this.$router.push({ name: "LearningSpacesHome" });
    },
    onUpdate() {
      this.$store.dispatch("getSpacesList");
    },
    navigateToLink(item) {
      if (item.text === this.currentOrg) {
        this.$router.push({
          name: "SelectedLearningSpace",
          params: { org: this.currentOrg }
        });
      } else {
        this.$router.push(item.to);
      }
    },
    onFeaturedResourcesUpdate() {
      this.getFeaturedResources();
    },
    onManageSectionUpdate() {
      this.getManageSection();
    },
    onToggleUpdate() {
      this.getManageSection();
    },
    onUpdateInitiative() {
      this.$store.dispatch("listInitiatives", { org: this.currentOrg });
    },
    onEmptyStateCTA() {
      window.open(this.allConfig.studioBaseURL, "_self");
    },
    getFeaturedResources() {
      this.$store
        .dispatch("getFeaturedResources", { org: this.currentOrg })
        .then(() => {
          this.featuredResources = this.getFeaturedResourcesList;
          this.getTags();
        })
        .catch(() => {
          this.getTags();
        });
    },
    getManageSection() {
      this.$store
        .dispatch("getManageSection", { org: this.currentOrg })
        .then(() => {
          this.sections = this.getManageSectionList;
          this.getFeaturedResources();
        });
    },

    getTags() {
      this.$store
        .dispatch("getTagsList", { org: this.currentOrg })
        .then(() => {
          this.currentInitiativeIdData = this.currentInitiativeId;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.edit-ls__wrapper {
  h5 {
    @include h5;
    margin-bottom: 32px;
  }
  .edit-ls__wrapper--div {
    background: #fff;
  }
}
</style>
